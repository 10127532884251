/* eslint-disable new-cap */
import PropTypes from 'prop-types'
import React from 'react'
import { Route, Switch } from 'react-router'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

/* Routing, dashboard and login */
import ModelRoute from '../containers/common/ModelRoute'
import { CONFIG } from '../selectors'
import Dash from '../components/Dash'
import PrivateRoute from './PrivateRoute'
import Login from './Login'
import Forgot from './Forgot'
import Reset from './Reset'
import Canvassing from './Canvassing'
import LoadUser from './LoadUser'


const Routes = ({ routes }) => (
  <Switch>
    <Route path="/canvassing" component={Canvassing} />
    <Route path="/login" component={Login} />
    <Route path="/forgot" component={Forgot} />
    <Route path="/:action(reset|activate)/:hash" component={Reset} />
    <LoadUser path="/secure/:site(\d+)">
      <PrivateRoute exact path="/secure/:site(\d+)" component={Dash} />
      {routes.franchises &&
        <ModelRoute routeConfig={routes.franchises} path="/secure/:site(\d+)/:model(franchises)" />
      }
      {routes.branches &&
        <ModelRoute routeConfig={routes.branches} path="/secure/:site(\d+)/:model(branches)" />
      }
      {routes.agents &&
        <ModelRoute routeConfig={routes.agents} path="/secure/:site(\d+)/:model(agents)" />
      }
      {routes.teams &&
        <ModelRoute routeConfig={routes.teams} path="/secure/:site(\d+)/:model(teams)" />
      }
      {routes.groups &&
        <ModelRoute routeConfig={routes.groups} path="/secure/:site(\d+)/:model(groups)" />
      }
      {routes.residential &&
        <ModelRoute routeConfig={routes.residential} path="/secure/:site(\d+)/:model(residential)" />
      }
      {routes.commercial &&
        <ModelRoute routeConfig={routes.commercial} path="/secure/:site(\d+)/:model(commercial)" />
      }
      {routes.holiday &&
        <ModelRoute routeConfig={routes.holiday} path="/secure/:site(\d+)/:model(holiday)" />
      }
      {routes.projects &&
        <ModelRoute routeConfig={routes.projects} path="/secure/:site(\d+)/:model(projects)" />
      }
      {routes.valuations &&
        <ModelRoute routeConfig={routes.valuations} path="/secure/:site(\d+)/:model(valuations)" />
      }
      {routes.syndication &&
        <ModelRoute routeConfig={routes.syndication} path="/secure/:site(\d+)/:model(syndication)/:log(residential|commercial|holiday|branches|agents)" />
      }
      {routes.contacts &&
        <ModelRoute routeConfig={routes.contacts} path="/secure/:site(\d+)/:model(contacts)" />
      }
      {routes.leads &&
        <ModelRoute routeConfig={routes.leads} path="/secure/:site(\d+)/:model(leads)" />
      }
      {routes.subscribers &&
        <ModelRoute routeConfig={routes.subscribers} path="/secure/:site(\d+)/:model(subscribers)" />
      }
      {routes.images &&
        <ModelRoute routeConfig={routes.images} path="/secure/:site(\d+)/:model(images)" />
      }
      {routes.documents &&
        <ModelRoute routeConfig={routes.documents} path="/secure/:site(\d+)/:model(documents)" />
      }
      {routes.assets &&
        <ModelRoute routeConfig={routes.assets} path="/secure/:site(\d+)/:model(assets)" />
      }
      {routes.profiles &&
        <ModelRoute routeConfig={routes.profiles} path="/secure/:site(\d+)/:model(profiles)" />
      }
      {routes.modules &&
        <ModelRoute routeConfig={routes.modules} path="/secure/:site(\d+)/:model(modules)" />
      }
      {routes['page-templates'] &&
        <ModelRoute routeConfig={routes['page-templates']} path="/secure/:site(\d+)/:model(page-templates)" />
      }
      {routes.articles &&
        <ModelRoute routeConfig={routes.articles} path="/secure/:site(\d+)/:model(articles)" />
      }
      {routes.cms &&
        <ModelRoute routeConfig={routes.cms} path="/secure/:site(\d+)/:model(cms)" />
      }
      {routes.navigation &&
        <ModelRoute routeConfig={routes.navigation} path="/secure/:site(\d+)/:model(navigation)" />
      }
      {routes['location-profiles'] &&
        <ModelRoute routeConfig={routes['location-profiles']} path="/secure/:site(\d+)/:model(location-profiles)" />
      }
      {routes.pages &&
        <ModelRoute routeConfig={routes.pages} path="/secure/:site(\d+)/:model(pages)" />
      }
      {routes.themes &&
        <ModelRoute routeConfig={routes.themes} path="/secure/:site(\d+)/:model(themes)" />
      }
      {routes.portals &&
        <ModelRoute routeConfig={routes.portals} path="/secure/:site(\d+)/:model(portals)" />
      }
      {routes.settings &&
        <ModelRoute routeConfig={routes.settings} path="/secure/:site(\d+)/:model(settings)" />
      }
      {routes.domains &&
        <ModelRoute routeConfig={routes.domains} path="/secure/:site(\d+)/:model(domains)" />
      }
      {routes['theme-settings'] &&
        <ModelRoute routeConfig={routes['theme-settings']} path="/secure/:site(\d+)/:model(theme-settings)" />
      }
      {routes.referrals &&
        <ModelRoute routeConfig={routes.referrals} path="/secure/:site(\d+)/:model(referrals)" />
      }
      {routes.offers &&
        <ModelRoute routeConfig={routes.offers} path="/secure/:site(\d+)/:model(offers)" />
      }
      {routes.deals &&
        <ModelRoute routeConfig={routes.deals} path="/secure/:site(\d+)/:model(deals)" />
      }
      {routes.newsletters &&
        <ModelRoute routeConfig={routes.newsletters} path="/secure/:site(\d+)/:model(newsletters)" />
      }
      {routes.applications &&
        <ModelRoute routeConfig={routes.applications} path="/secure/:site(\d+)/:model(applications)" />
      }
      {routes.integrations &&
        <ModelRoute routeConfig={routes.integrations} path="/secure/:site(\d+)/:model(integrations)" />
      }
      {routes['newsletter-templates'] &&
        <ModelRoute routeConfig={routes['newsletter-templates']} path="/secure/:site(\d+)/:model(newsletter-templates)" />
      }
      {routes.insights &&
        <ModelRoute routeConfig={routes.insights} path="/secure/:site(\d+)/:model(insights)" />
      }
      <PrivateRoute path="/secure/:site(\d+)" component={Login} />
    </LoadUser>
    <Route component={Login} />
  </Switch>
)

Routes.propTypes = {
  routes: PropTypes.object
}

const mapStateToProps = state => (
  {
    routes: CONFIG(state, 'routes')
  }
)

export default connect(mapStateToProps, null)(withImmutablePropsToJS(React.memo(Routes)))
